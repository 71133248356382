export const FOOTER_NAV = [
  {
    name: 'tours',
    href_en: 'https://www.victoryarena.com/en/tours',
    href_ar: 'https://www.victoryarena.com/ar/tours',
  },
  {
    name: 'news',
    href_en: 'https://www.victoryarena.com/en/news',
    href_ar: 'https://www.victoryarena.com/ar/news',
  },
  {
    name: 'seating',
    href_en: 'https://www.victoryarena.com/en/seating',
    href_ar: 'https://www.victoryarena.com/ar/seating',
  },
  {
    name: 'routes',
    href_en: 'https://www.victoryarena.com/en/routs',
    href_ar: 'https://www.victoryarena.com/ar/routs',
  },
  {
    name: 'parking',
    href_en: 'https://www.victoryarena.com/en/parking',
    href_ar: 'https://www.victoryarena.com/ar/parking',
  },
  {
    name: 'faq',
    href_en: 'https://www.victoryarena.com/en/faqs',
    href_ar: 'https://www.victoryarena.com/ar/faqs',
  },
  {
    name: 'contact_us',
    href_en: 'https://www.victoryarena.com/en/contact-us',
    href_ar: 'https://www.victoryarena.com/ar/contact-us',
  },
];

export const FOOTER_SUB_NAV = [
  {
    name: 'cookie_policy',
    href_en: 'https://www.victoryarena.com/en/cookie-policy',
    href_ar: 'https://www.victoryarena.com/ar/cookie-policy',
  },
  {
    name: 'terms_and_conditions',
    href_en: 'https://www.victoryarena.com/en/terms-and-conditions',
    href_ar: 'https://www.victoryarena.com/ar/terms-and-conditions',
  },
  {
    name: 'ticketing_terms_and_conditions',
    href_en: 'https://www.victoryarena.com/en/ticketing-terms-and-conditions',
    href_ar: 'https://www.victoryarena.com/ar/ticketing-terms-and-conditions',
  },
  {
    name: 'privacy_policy',
    href_en: 'https://www.victoryarena.com/en/privacy-policy',
    href_ar: 'https://www.victoryarena.com/ar/privacy-policy',
  },
];

export const FOOTER_CONTACT_US = [
  {
    name: 'location',
    href: '',
    icon: '/images/footer/location-white.png',
  },
];
