export const HEADER_NAV = [
  {
    title: 'tours',
    href_en: 'https://www.victoryarena.com/en/tours',
    href_ar: 'https://www.victoryarena.com/ar/tours',
    external: true,
  },
  {
    title: 'news',
    href_en: 'https://www.victoryarena.com/en/news',
    href_ar: 'https://www.victoryarena.com/ar/news',
    external: true,
  },
  {
    title: 'seating',
    href_en: 'https://www.victoryarena.com/en/seating',
    href_ar: 'https://www.victoryarena.com/ar/seating',
    external: true,
  },
  {
    title: 'routes',
    href_en: 'https://www.victoryarena.com/en/routs',
    href_ar: 'https://www.victoryarena.com/ar/routs',
    external: true,
  },
  {
    title: 'parking',
    href_en: 'https://www.victoryarena.com/en/parking',
    href_ar: 'https://www.victoryarena.com/ar/parking',
    external: true,
  },
  {
    title: 'faq',
    href_en: 'https://www.victoryarena.com/en/faqs',
    href_ar: 'https://www.victoryarena.com/ar/faqs',
    external: true,
  },
  {
    title: 'contact_us',
    href_en: 'https://www.victoryarena.com/en/contact-us',
    href_ar: 'https://www.victoryarena.com/ar/contact-us',
    external: true,
  },
];
